import { useState, useEffect } from "react"
import { Frame, addPropertyControls, ControlType } from "framer"

function TimeBasedText(props) {
    const [greeting, setGreeting] = useState("Good Evening")

    useEffect(() => {
        const updateGreeting = () => {
            const hour = new Date().getHours()
            if (hour < 12) {
                setGreeting("Good Morning")
            } else if (hour < 18) {
                setGreeting("Good Afternoon")
            } else {
                setGreeting("Good Evening")
            }
        }

        updateGreeting()
        const interval = setInterval(updateGreeting, 60000) // Update every minute
        return () => clearInterval(interval) // Clean up on unmount
    }, [])

    return (
        <Frame
            width="100%"
            height="100%"
            background="transparent"
            style={{
                fontFamily: props.fontFamily,
                color: props.color,
                textTransform: props.textTransform,
                fontSize: props.fontSize,
                letterSpacing: props.letterSpacing,
                fontWeight: props.fontWeight,
                textAlign: props.textAlign, // Apply text alignment
                display: "flex",
                alignItems: "center", // Center text vertically within the Frame
                justifyContent: "center", // Align text to the center
                padding: "0", // Optional padding
            }}
        >
            {greeting}
        </Frame>
    )
}

TimeBasedText.defaultProps = {
    fontFamily:
        '"Roslindale Text Regular", "Roslindale Text Regular Placeholder", sans-serif',
    color: "#5fb4f2",
    textTransform: "uppercase",
    fontSize: "16px",
    letterSpacing: "0.1em",
    fontWeight: 400,
    textAlign: "left", // Default text alignment
}

addPropertyControls(TimeBasedText, {
    fontFamily: { type: ControlType.String, title: "Font Family" },
    color: { type: ControlType.Color, title: "Color" },
    textTransform: { type: ControlType.String, title: "Text Transform" },
    fontSize: { type: ControlType.String, title: "Font Size" },
    letterSpacing: { type: ControlType.String, title: "Letter Spacing" },
    fontWeight: { type: ControlType.Number, title: "Font Weight" },
    textAlign: {
        type: ControlType.Enum,
        title: "Text Align",
        options: ["left", "center", "right", "justify"],
        optionTitles: ["Left", "Center", "Right", "Justify"],
        defaultValue: "left",
    },
})

export default TimeBasedText
